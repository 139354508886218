import { Col, Row } from "antd";

export const Admin = () => {
  return (
    <div className="main">
      <Row gutter={[18, 18]}>
        <Col span={24}>
          <h1>Admin</h1>
        </Col>
        <Col span={24}>
          <h2>Company Secretary</h2>
          <p>SSP Contact Info</p>
          <ul>
            <li>Saroma</li>
            <li>
              <a href="tel:+9477 771 0073">{"+9477 771 0073"}</a>
            </li>
            <li>
              <a href="https://wa.me/94777710073">{"whatsApp"}</a>
            </li>

            <li>
              <a href=" https://mail.google.com/mail/u/0/?fs=1&to=saroma.e@ssp.lk,&cc=viji@viji.com,&su=Re&body=Dear%20Saroma,&tf=cm">
                {"saroma.e@ssp.lk"}
              </a>
            </li>
          </ul>
        </Col>
        <Col span={24}>
          <h2>HNB Bank Details</h2>
          <ul style={{ listStyleType: "none" }}>
            <li>A/C name: Tech For Good Pvt Ltd.</li>
            <li>Account Number: 703010008708</li>
            <li>Beneficiary Bank: Hatton National Bank</li>
            <li>Bank code: 7083,</li>
            <li>Branch code: 703</li>
          </ul>
          <p>VAT No:</p>
          <p>Registered Address:</p>
        </Col>
      </Row>
    </div>
  );
};
